class BackToTop extends HTMLElement {
    constructor() {
      super();
      const button = this.querySelector('button');
      button.addEventListener('click', this.topFunction);
    }

    topFunction() {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    
  }
  
  customElements.define('back-to-top', BackToTop);
  